const baseUrl = process.env.REACT_APP_API_URL
const syncUrl = process.env.REACT_APP_WP_SYNC_URL
const syncCreds = process.env.REACT_APP_WP_SYNC_CREDS
const wpCreds = process.env.REACT_APP_STAGE_CREDS
export const getItem = (token, apiPath, fields, callback) => {
  fetch(baseUrl + apiPath, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((resp) => {
      return resp.json()
    })
    .then((data) => {
      if (!Array.isArray(data) || data.length < 1) {
        throw new Error("Non existing item")
      } else {
        data = data[0]
      }

      // Transform data before present
      for (var i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field.type && field.name in data) {
          if (field.type.toLowerCase() === "date") {
            if (data[field.name]) {
              data[field.name] = new Date(data[field.name])
            }
          }

          // Handle JSON data
          if (field.json && data[field.name]) {
            data[field.name] = JSON.parse(data[field.name])
          }
        }
      }
      // Callback with data
      callback(data)
    })
    .catch(function (e) {
      console.log("something went wrong while fetching items")
      console.log(e)
      callback(false, e)
    })
}

export const getItems = (token, apiPath, callback) => {
  fetch(baseUrl + apiPath, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((resp) => resp.json())
    .then((data) => {
      callback(data)
    })
    .catch(function (e) {
      console.log("something went wrong while fetching items")
      console.log(e)
      callback(false, e)
    })
}

export const deleteItem = (token, apiPath, callback) => {
  const reqUrl = baseUrl + apiPath
  const reqMethod = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  }

  fetch(reqUrl, reqMethod)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(`${response.status} ${response.statusText}`)
      }
    })
    .then((data) => callback(data))
    .catch((err) => callback(false, err))
}

export const postItem = (token, apiPath, data, userName, fields, callback) => {
  // Transform data before save
  let nameField = fields.filter((f) => {
    return f.nameField
  })
  nameField = nameField && nameField.length > 0 ? nameField[0] : false

  for (var i = 0; i < fields.length; i++) {
    const field = fields[i]

    // Assign internal name
    if (field.internalNameField && nameField && nameField.name in data) {
      data[field.name] = data[nameField.name]
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/ /g, "-")
    }
    if (field.type && field.name in data) {
      if (field.type.toLowerCase() === "number") {
        data[field.name] = data[field.name] || null
      }

      // Handle JSON data
      if (field.json) {
        data[field.name] = JSON.stringify(data[field.name])
      }
    }
  }

  // Make POST request
  const reqUrl = baseUrl + apiPath
  const reqMethod = {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      newItems: Array.isArray(data) ? data : [data],
      userName,
    }),
  }

  fetch(reqUrl, reqMethod)
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => callback(false, err))
}

export const putItem = (token, apiPath, data, userName, fields, callback) => {
  // Transform data before save
  for (var i = 0; i < fields.length; i++) {
    const field = fields[i]
    if (field.type && field.name in data) {
      if (field.type.toLowerCase() === "number") {
        data[field.name] = data[field.name] || null
      } else if (field.type.toLowerCase() === "date") {
        if (!data[field.name]) {
          data[field.name] = null
        } else {
          const tempDate = new Date(data[field.name])
          const dateFormatted = tempDate.toISOString()
          data[field.name] = dateFormatted
        }
      }

      // Handle JSON data
      if (field.json) {
        data[field.name] = JSON.stringify(data[field.name])
      }
    }
  }

  // Make PUT request
  const reqUrl = baseUrl + apiPath
  const reqMethod = {
    method: "PUT",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      modifiedItems: [data],
      userName,
    }),
  }

  fetch(reqUrl, reqMethod)
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => callback(false, err))
}

export const putItems = (token, apiPath, data, userName, fields, callback) => {
  // Transform data before save (fields array contains Material-table columns)
  for (var j = 0; j < data.length; j++) {
    const item = data[j]
    for (var i = 0; i < fields.length; i++) {
      const field = fields[i]
      const fieldName = field.field
      if (field.type && fieldName in item) {
        if (field.type.toLowerCase() === "numeric") {
          item[fieldName] = item[fieldName] || null
        } else if (field.type.toLowerCase() === "date") {
          if (!item[fieldName]) {
            item[fieldName] = null
          } else {
            const tempDate = new Date(item[fieldName])
            const dateFormatted = tempDate.toISOString()
            item[fieldName] = dateFormatted
          }
        }
      }
    }
  }

  // Make PUT request
  const reqUrl = baseUrl + apiPath
  const reqMethod = {
    method: "PUT",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      modifiedItems: data,
      userName,
    }),
  }

  fetch(reqUrl, reqMethod)
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => callback(false, err))
}

export const recalculateScore = (token, apiPath, userName, market, callback) => {
  const reqUrl = baseUrl + apiPath
  const reqMethod = {
    method: "PUT",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      market: market,
      userName,
    }),
  }

  fetch(reqUrl, reqMethod)
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => callback(false, err))
}

export const wpSync = (dataType, item, market = "", isNew = false, callback) => {
  const formData = new FormData()
  formData.append("data-type", dataType)
  if (item) formData.append("item", item)

  if (market) formData.append("market", market)
  if (isNew) formData.append("is-new", isNew)

  const reqUrl = syncUrl
  const reqMethod = {
    method: "POST",
    headers: {
      Authorization: "Basic " + window.btoa(wpCreds),
      auth: "Basic " + window.btoa(syncCreds),
    },
    body: formData,
  }

  fetch(reqUrl, reqMethod)
    .then((response) => response.json())
    .then((data) => {
      return callback(data)
    })
    .catch((err) => {
      return callback(false, err)
    })
}
