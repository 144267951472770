import React, { useEffect, useState, useCallback, useRef } from "react"
import {
  // eslint-disable-next-line no-unused-vars
  Router,
  Link,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom"
import { useStateValue } from "../../state"
import PropTypes from "prop-types"
import { useAuth0 } from "@auth0/auth0-react"
import ErrorDisplay from "../reusable/ErrorDisplay"
import SnackbarComponent from "../reusable/SnackbarComponent"
import TableSkeleton from "../reusable/TableSkeleton"
import AlertPrompt from "../reusable/AlertPrompt"
import { getItems, deleteItem, postItem, wpSync } from "../../helpers/ItemHelper"
import { countryToFlag } from "../../helpers/GeneralHelper"
import ItemAdder from "./ItemAdder"
import EditableItemTable from "./EditableItemTable"
import { getMenuItem, getFields } from "../../helpers/GeneralHelper"
import { Constants } from "../../config"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import Tooltip from "@mui/material/Tooltip"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableSortLabel from "@mui/material/TableSortLabel"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import PublicIcon from "@mui/icons-material/Public"
import IconButton from "@mui/material/IconButton"
import CheckIcon from "@mui/icons-material/Check"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import ErrorIcon from "@mui/icons-material/Error"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { makeStyles } from "@mui/styles"
import { Typography, Avatar } from "@mui/material"
import CloudSyncIcon from "@mui/icons-material/CloudSync"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 550,
    margin: -theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableContainer: {
    height: "calc(100vh - 140px)",
  },
  mainTableColumn: {
    minWidth: 280,
  },
  tableParent: {
    padding: theme.spacing(1),
  },
  headerCell: {
    backgroundColor: "#fff",
    fontWeight: 600,
  },
  buttonsHeaderCell: {
    width: 135,
    backgroundColor: "#fff",
  },
  buttonsCell: {
    whiteSpace: "nowrap",
  },
  statusChip: {
    margin: theme.spacing(0, 0, 0, 1),
    "&:first-child": {
      margin: theme.spacing(0, 0, 0, 2),
    },
  },
  statusChipAvatar: {
    backgroundColor: "transparent",
    fontSize: "1em !important",
  },
  ellipsis: {
    maxWidth: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 14,
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
  adderLinkButton: {
    padding: "0 6px",
  },
  emptyFieldsWarningTooltip: {
    verticalAlign: "middle",
    margin: theme.spacing(0, 0, 0, 2),
  },
}))

function ItemTable(props) {
  const routParams = useParams()
  const classes = useStyles()

  const location = useLocation()

  const history = useHistory()

  const [{ appBarContext }, dispatch] = useStateValue()

  const { getAccessTokenSilently, user } = useAuth0()

  const { name: currentUserName } = user

  const [apiUrl, setApiUrl] = useState(undefined),
    [params, setParams] = useState(undefined),
    [menuItem, setMenuItem] = useState(undefined),
    [items, setItems] = useState([]),
    [fields, setFields] = useState(undefined),
    [mainColumn, setMainColumn] = useState(undefined),
    [visibleRows, setVisibleRows] = useState([]),
    [rowsPerPage, setRowsPerPage] = useState(50),
    [page, setPage] = useState(0),
    [isLoading, setIsLoading] = useState(true),
    [isError, setIsError] = useState(false),
    [errorDetails, setErrorDetails] = useState(undefined),
    [orderBy, setOrderBy] = useState(undefined),
    [order, setOrder] = useState("asc"),
    [showSnackbar, setShowSnackbar] = useState(false),
    [snackbarProps, setSnackbarProps] = useState({}),
    [adderIsOpen, setAdderIsOpen] = useState(false),
    [adderItems, setAdderItems] = useState(undefined),
    [alertPromptProps, setAlertPromptProps] = useState(false),
    [bulkEditAvailable, setBulkEditAvailable] = useState(false),
    [bulkEdit, setBulkEdit] = useState(false)

  const paramsRef = useRef()
  paramsRef.current = params

  const itemsRef = useRef()
  itemsRef.current = items

  const addItemStatusHighlighting = useCallback(
    (data) => {
      if (
        paramsRef &&
        paramsRef.current &&
        Constants.statusFieldsType &&
        paramsRef.current.type.toLowerCase() === Constants.statusFieldsType &&
        Array.isArray(data)
      ) {
        data.forEach((d) => {
          const statuses = []
          for (let i = 0; i < Constants.statusFields.length; i++) {
            const statusField = Constants.statusFields[i]
            const value = d[statusField.name]
            if (
              value &&
              value.toUpperCase() !== Constants.activeStatus.toUpperCase()
            ) {
              const market =
                paramsRef && paramsRef.current ? paramsRef.current.market : false
              statuses.push(
                <Tooltip
                  title={statusField.title}
                  className={classes.statusChip}
                  key={"status-chip-" + statusField.name}
                >
                  <Chip
                    component="span"
                    avatar={
                      (market && !statusField.root && (
                        <Avatar
                          className={classes.statusChipAvatar}
                          component="span"
                        >
                          {countryToFlag(market)}
                        </Avatar>
                      )) ||
                      undefined
                    }
                    icon={(!market || statusField.root) && <PublicIcon />}
                    size="small"
                    label={value.replace(/[^A-Za-z ]+/g, "")}
                  />
                </Tooltip>
              )
            }
          }
          if (statuses.length > 0) {
            d["status_message"] = statuses
          }
        })
      }
    },
    [paramsRef, classes.statusChip, classes.statusChipAvatar]
  )

  const addItemEmptyFieldsHighlighting = useCallback(
    (data) => {
      if (paramsRef && paramsRef.current && Constants.emptyFieldsWarnings) {
        const emptyFieldsWarnings = Constants.emptyFieldsWarnings.filter(
          (e) =>
            paramsRef.current.type === e.itemType &&
            (!e.onlyInMarkets ||
              e.onlyInMarkets.indexOf(paramsRef.current.market > -1)) &&
            (!e.disableInMarkets ||
              e.disableInMarkets.indexOf(paramsRef.current.market) < 0)
        )

        if (emptyFieldsWarnings && emptyFieldsWarnings.length > 0) {
          const emptyFieldsWarning = emptyFieldsWarnings[0]
          data.forEach((d) => {
            let numberOfEmptyFields = 0
            for (let i = 0; i < emptyFieldsWarning.fields.length; i++) {
              if (!d[emptyFieldsWarning.fields[i]]) numberOfEmptyFields++
            }
            if (
              (emptyFieldsWarning.requireAll && numberOfEmptyFields > 0) ||
              (!emptyFieldsWarning.requireAll &&
                numberOfEmptyFields >= emptyFieldsWarning.fields.length)
            ) {
              d["empty_fields_message"] = (
                <Tooltip
                  title={emptyFieldsWarning.message}
                  className={classes.emptyFieldsWarningTooltip}
                >
                  <Chip
                    component="span"
                    color="secondary"
                    icon={<ErrorIcon />}
                    size="small"
                    label={emptyFieldsWarning.label}
                  />
                </Tooltip>
              )
            }
          })
        }
      }
    },
    [paramsRef, classes.emptyFieldsWarningTooltip]
  )

  const loadItems = useCallback(() => {
    setIsLoading(true)
    getAccessTokenSilently().then((token) => {
      getItems(token, apiUrl, (data, error) => {
        if (data) {
          addItemStatusHighlighting(data)
          addItemEmptyFieldsHighlighting(data)

          setItems(data)
          setVisibleRows(data)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setIsError(true)
          setErrorDetails(error.toString())
        }
      })
    })
  }, [
    apiUrl,
    getAccessTokenSilently,
    addItemStatusHighlighting,
    addItemEmptyFieldsHighlighting,
  ])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
  }

  const descendingComparator = (a, b, orderBy) => {
    const aTerm =
      a[orderBy] && typeof a[orderBy] === "string"
        ? a[orderBy].toLowerCase()
        : a[orderBy]
    const bTerm =
      b[orderBy] && typeof b[orderBy] === "string"
        ? b[orderBy].toLowerCase()
        : b[orderBy]
    if (bTerm < aTerm) {
      return -1
    }
    if (bTerm > aTerm) {
      return 1
    }
    return 0
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  const deleteOrRemoveItemClicked = (item) => {
    const dataType = routParams.type
    const itemId = item.id
    let name = item[Constants.internalNameColumn]
    if (dataType === "casinolicenses") {
      name = item[Constants.casinoLicensesNameColumn]
    } else if (dataType === "gamestudiolicenses") {
      name = item[Constants.gameStudioNameColumn]
    }

    setAlertPromptProps({
      open: true,
      title: "Please confirm",
      content: (
        <>
          <p>
            Are you sure you want to delete: <strong>{name}</strong>?
          </p>
          <p>
            <strong>This cannot be undone.</strong>
          </p>
        </>
      ),
      cancelLabel: "Cancel",
      continueLabel: "Continue",
      cancelEvent: () => {
        setAlertPromptProps(false)
      },
      continueEvent: () => {
        setAlertPromptProps(false)
        setIsLoading(true)
        getAccessTokenSilently().then((token) => {
          deleteItem(token, apiUrl + itemId, (results) => {
            setIsLoading(false)
            loadItems()
            if (results && !results.errno) {
              setShowSnackbar(true)
              setSnackbarProps({
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: name + " has been deleted",
                icon: <CheckIcon />,
                autoHideDuration: 2000,
              })

              if (
                dataType === "casinolicenses" ||
                dataType === "gamestudiolicenses"
              ) {
                setShowSnackbar(true)
                setSnackbarProps({
                  messageClass: classes.snackbarMessage,
                  message: "Sync into WP in process",
                  icon: <CloudSyncIcon />,
                })
                wpSync(dataType, name, "", false, (syncResults) => {
                  if (syncResults.status && syncResults.status === "updated") {
                    setShowSnackbar(true)
                    setSnackbarProps({
                      messageClass: classes.snackbarMessage,
                      closeEvent: handleCloseSnackbar,
                      message: syncResults.message,
                      icon: <CheckIcon />,
                      autoHideDuration: 1500,
                    })
                  } else {
                    console.log("Data sync error: ", syncResults)
                    setShowSnackbar(true)
                    setSnackbarProps({
                      class: classes.errorSnackbar,
                      messageClass: classes.snackbarMessage,
                      closeEvent: handleCloseSnackbar,
                      message: "Sync Error: " + syncResults.message,
                      icon: <ErrorOutlineIcon />,
                    })
                  }
                })
              }
            } else {
              setShowSnackbar(true)
              setSnackbarProps({
                class: classes.errorSnackbar,
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: "Sorry, an error occured when deleting item",
                icon: <ErrorOutlineIcon />,
              })
            }
            dispatch({
              type: "changeAppBar",
              newAppBar: {
                searchTerm: "",
              },
            })
          })
        })
      },
    })
  }

  const addClicked = useCallback(() => {
    const params = paramsRef.current
    let parentApiUrl

    if (params.level === "root") {
      parentApiUrl = `/${params.level}/${params.type}/root/`
    } else {
      parentApiUrl = `/root/${params.type}/${params.market}/`
    }

    setAdderIsOpen(true)

    getAccessTokenSilently().then((token) => {
      getItems(token, parentApiUrl, (data, error) => {
        if (data && Array.isArray(data)) {
          const currentItems = itemsRef.current.map((r) => {
            return r[Constants.internalNameColumn]
          })
          data = data.filter((f) => {
            return currentItems.indexOf(f[Constants.internalNameColumn]) < 0
          })
          setAdderItems(
            data.map((t) => {
              return {
                [Constants.internalNameColumn]: t[Constants.internalNameColumn],
                [Constants.nameColumn]: t[Constants.nameColumn],
              }
            })
          )
        } else {
          setAdderIsOpen(false)
          setIsError(true)
          setErrorDetails(error.toString())
        }
      })
    })
  }, [itemsRef, getAccessTokenSilently])

  const refreshClicked = useCallback(() => {
    loadItems()
  }, [loadItems])

  const toggleBulkEditClicked = useCallback((event) => {
    const el = event.currentTarget
    if (el) {
      if (el.classList.contains("toggle-bulk-edit-active")) {
        el.classList.remove("toggle-bulk-edit-active")
        setBulkEdit(false)
      } else {
        el.classList.add("toggle-bulk-edit-active")
        setBulkEdit(true)
      }
    }
  }, [])

  const handleAdderClose = () => {
    setAdderItems(undefined)
    setAdderIsOpen(false)
  }

  const handleAdderSave = (data) => {
    setAdderItems(undefined)
    setAdderIsOpen(false)
    setIsLoading(true)
    if (data && Array.isArray(data) && data.length > 0) {
      data = data.map((t) => {
        return { [Constants.internalNameColumn]: t[Constants.internalNameColumn] }
      })

      getAccessTokenSilently().then((token) => {
        postItem(
          token,
          apiUrl,
          data,
          currentUserName,
          [Constants.internalNameColumn],
          (results) => {
            setIsLoading(false)
            if (results && !results.errno) {
              setShowSnackbar(true)
              setSnackbarProps({
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: "Items has been added",
                icon: <CheckIcon />,
                autoHideDuration: 1500,
              })
              loadItems()
            } else {
              setIsError(true)
              setErrorDetails(
                results.sqlMessage || "Something went wrong when adding items"
              )
            }
          }
        )
      })
    }
  }

  useEffect(() => {
    setItems([])
    if (apiUrl) {
      loadItems()
    }
  }, [apiUrl, loadItems])

  useEffect(() => {
    const tableFields = getFields(location)
    // props.location && props.location.fields
    //   ? props.location.fields
    //   : getFields(location)
    setFields(tableFields)

    const mainColumnField = tableFields.filter((f) => {
      return f.mainTableColumn
    })
    if (mainColumnField && mainColumnField.length > 0) {
      setOrderBy(mainColumnField[0].name)
      setMainColumn(mainColumnField[0].name)
    } else {
      setShowSnackbar(true)
      setSnackbarProps({
        class: classes.errorSnackbar,
        messageClass: classes.snackbarMessage,
        closeEvent: handleCloseSnackbar,
        message: "Could not identify table main column",
        icon: <ErrorOutlineIcon />,
      })
    }
  }, [props, classes.errorSnackbar, classes.snackbarMessage])

  useEffect(() => {
    setIsError(false)
    setErrorDetails(undefined)
    setParams(routParams)
    setApiUrl(
      `/${routParams.level}/${routParams.type}/${
        routParams.market ? routParams.market : "root"
      }/`
    )

    const menuItem = getMenuItem(location)
    setMenuItem(menuItem)
  }, [routParams, location])

  useEffect(() => {
    if (menuItem) {
      dispatch({
        type: "changeAppBar",
        newAppBar: {
          enableSearch: true,
          enableFilter: true,
          filterFields: menuItem.filters,
          fields,
          searchTerm: "",
          refreshAction: () => refreshClicked,
          refreshActionTooltip: "Refresh table",
          enableCreate: menuItem && menuItem.allowCreate,
          enableAdd: menuItem && menuItem.allowAdd,
          addAction: menuItem && menuItem.allowAdd ? () => addClicked : undefined,
          title: `Browsing ${menuItem.name} ${
            menuItem.marketLabel ? "(" + menuItem.marketLabel + ")" : ""
          }`,
        },
      })
    }
  }, [dispatch, menuItem, addClicked, fields, refreshClicked])

  useEffect(() => {
    const bulkEditIsConfigured = menuItem && menuItem.bulkEdit
    setBulkEditAvailable(bulkEditIsConfigured)
    dispatch({
      type: "changeAppBar",
      newAppBar: {
        toggleBulkEditAction: bulkEditIsConfigured
          ? () => toggleBulkEditClicked
          : undefined,
        toggleBulkEditActionTooltip:
          bulkEditIsConfigured && menuItem.bulkEdit.tooltip
            ? menuItem.bulkEdit.tooltip
            : undefined,
      },
    })
  }, [dispatch, menuItem, toggleBulkEditClicked])

  useEffect(() => {
    let filteredRows = appBarContext.searchTerm
      ? items.filter((r) => {
          return (
            r[mainColumn]
              .toLowerCase()
              .indexOf(appBarContext.searchTerm.toLowerCase()) !== -1
          )
        })
      : items

    const missingValueFilter = appBarContext.missingValueFilterFields
    if (missingValueFilter && missingValueFilter.length > 0) {
      const missingValueFieldNames = missingValueFilter.map((m) => m.name)
      filteredRows = filteredRows.filter((f) => {
        let numberOfMissingValues = 0
        for (let i = 0; i < missingValueFieldNames.length; i++) {
          if (!f[missingValueFieldNames[i]]) numberOfMissingValues++
        }
        return numberOfMissingValues >= missingValueFieldNames.length
      })
    }

    const fieldFilters = appBarContext.activeFieldFilters
    if (fieldFilters && Object.keys(fieldFilters).length > 0) {
      let newFilteredRows = filteredRows
      Object.keys(fieldFilters).forEach((key) => {
        const fieldFilter = fieldFilters[key]
        if (fieldFilter && fieldFilter.length > 0) {
          let tempNewFilteredRows = []
          fieldFilter.forEach((v) => {
            tempNewFilteredRows = [
              ...tempNewFilteredRows,
              ...newFilteredRows.filter((r) => r[key] && r[key] === v.name),
            ]
          })
          newFilteredRows = tempNewFilteredRows
        }
      })
      filteredRows = newFilteredRows
    }

    setVisibleRows(filteredRows)
  }, [
    appBarContext.searchTerm,
    appBarContext.missingValueFilterFields,
    appBarContext.activeFieldFilters,
    items,
    mainColumn,
  ])

  useEffect(() => {
    return () => {
      dispatch({
        type: "changeAppBar",
        newAppBar: {
          enableSearch: false,
          enableFilter: false,
          fields: [],
          title: undefined,
          enableCreate: false,
          enableAdd: false,
          addAction: undefined,
          refreshAction: undefined,
          toggleBulkEditAction: undefined,
          toggleBulkEditActionTooltip: undefined,
        },
      })
    }
  }, [dispatch])

  const adjustedRowsPerPage = visibleRows
    ? Math.min(rowsPerPage, visibleRows.length)
    : rowsPerPage
  const emptyRows =
    adjustedRowsPerPage -
    Math.min(adjustedRowsPerPage, visibleRows.length - page * adjustedRowsPerPage)

  return (
    <Paper className={classes.root}>
      {alertPromptProps && <AlertPrompt {...alertPromptProps} />}
      {showSnackbar && <SnackbarComponent open={showSnackbar} {...snackbarProps} />}
      {((isLoading || !fields || !mainColumn) && <TableSkeleton rows={12} />) ||
        (isError && (
          <ErrorDisplay title="Something went wrong" description={errorDetails} />
        )) || (
          <div>
            {bulkEditAvailable && (
              <div style={{ display: bulkEdit ? null : "none" }}>
                <EditableItemTable
                  config={menuItem.bulkEdit}
                  rows={visibleRows}
                  apiUrl={apiUrl}
                  reload={loadItems}
                />
              </div>
            )}
            {params && (
              <ItemAdder
                open={adderIsOpen}
                items={adderItems}
                handleClose={handleAdderClose}
                handleSave={handleAdderSave}
              >
                Or add new items in the
                <Button
                  className={classes.adderLinkButton}
                  onClick={() => history.push(`/root/${params.type}/root/new`)}
                >
                  Central database
                </Button>
              </ItemAdder>
            )}
            <div
              className={classes.tableParent}
              style={{ display: bulkEdit ? "none" : null }}
            >
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.buttonsHeaderCell}></TableCell>
                      {fields
                        .filter((f) => {
                          return (
                            f.displayInBrowse &&
                            !f.hidden &&
                            (!params.market || f.hideInMarket !== params.market)
                          )
                        })
                        .map((f) => {
                          return (
                            <TableCell
                              className={classes.headerCell}
                              key={f.name}
                              sortDirection={orderBy === f.name ? order : false}
                              align={f.type === "number" ? "right" : "left"}
                            >
                              <TableSortLabel
                                active={orderBy === f.name}
                                direction={orderBy === f.name ? order : "asc"}
                                onClick={createSortHandler(f.name)}
                              >
                                {f.title}
                                {orderBy === f.name ? (
                                  <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                      ? "sorted descending"
                                      : "sorted ascending"}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                          )
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(visibleRows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell className={classes.buttonsCell}>
                            <IconButton
                              aria-label="remove"
                              onClick={() => deleteOrRemoveItemClicked(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Link
                              to={{
                                pathname: location.pathname + "/" + row.id,
                                fields,
                              }}
                            >
                              <IconButton aria-label="edit">
                                <EditIcon />
                              </IconButton>
                            </Link>
                          </TableCell>
                          {fields
                            .filter((f) => {
                              return (
                                f.displayInBrowse &&
                                !f.hidden &&
                                (!params.market || f.hideInMarket !== params.market)
                              )
                            })
                            .map((f) => {
                              return (
                                <TableCell
                                  key={f.name}
                                  className={
                                    f.mainTableColumn ? classes.mainTableColumn : ""
                                  }
                                  scope={f.scope}
                                  align={f.type === "number" ? "right" : "left"}
                                >
                                  <Typography className={classes.ellipsis}>
                                    {row[f.name]}
                                    {f.mainTableColumn &&
                                      row["empty_fields_message"]}
                                    {f.mainTableColumn && row["status_message"]}
                                  </Typography>
                                </TableCell>
                              )
                            })}
                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200, 500]}
                component="div"
                count={visibleRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
    </Paper>
  )
}

ItemTable.propTypes = {
  // location: PropTypes.object.isRequired,
  match: PropTypes.object,
}

export default ItemTable
